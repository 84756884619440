<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn outlined class="font-weight-bold me-2" :to="`/plans`">{{ $t('Cancel') }}</v-btn>
            <v-btn color="purple" class="font-weight-bold me-0" @click="onSubmit" :loading="loading"
                :disabled="loading">{{ $t('Save') }}</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-text class="px-0 py-0">
                        <v-form>
                            <v-container>
                                <v-card>
                                    <v-card-title>{{ $t('Plan Default List') }}</v-card-title>
                                    <v-card-text>
                                        <v-combobox v-model="selected.plan_default_list" :label="$t('Add')" :items="plans"
                                        item-text="name" item-value="id" @change="onChangePlan('plan_default_list')" clearable></v-combobox>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left col-1">{{ $t('#') }}</th>
                                                        <th class="text-left col-2">{{ $t('Name') }}</th>
                                                        <th class="text-left col-2">{{ $t('Sort') }}</th>
                                                        <th class="text-left col-1">{{ $t('Action') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(plan, index) in item.plan_default_list" :key="index">
                                                        <td>{{ plan.id }}</td>
                                                        <td>{{ planName(plan.id) }}</td>
                                                        <td><v-text-field v-model="plan.sort"></v-text-field></td>
                                                        <td>
                                                            <v-btn small color="error" class="mx-2" @click="onDeletePlan(index, 'plan_default_list')">
                                                                <v-icon>mdi-delete</v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>

                                <v-card>
                                    <v-card-title>{{ $t('Mobile Plan List') }}</v-card-title>
                                    <v-card-text>
                                        <v-combobox v-model="selected.plan_mobile_list" :label="$t('Add')" :items="plans"
                                        item-text="name" item-value="id" @change="onChangePlan('plan_mobile_list')" clearable></v-combobox>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left col-1">{{ $t('#') }}</th>
                                                        <th class="text-left col-2">{{ $t('Name') }}</th>
                                                        <th class="text-left col-2">{{ $t('Sort') }}</th>
                                                        <th class="text-left col-1">{{ $t('Action') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(plan, index) in item.plan_mobile_list" :key="index">
                                                        <td>{{ plan.id }}</td>
                                                        <td>{{ planName(plan.id) }}</td>
                                                        <td><v-text-field v-model="plan.sort"></v-text-field></td>
                                                        <td>
                                                            <v-btn small color="error" class="mx-2" @click="onDeletePlan(index, 'plan_mobile_list')">
                                                                <v-icon>mdi-delete</v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import settingService from '@/services/settingService'
import planService from '@/services/planService'

export default {
    data() {
        return {
            loading: false,
            item: {
                plan_default_list: [],
                plan_mobile_list: [],
            },
            plans: [],
            selected: {
                plan_default_list: null,
                plan_mobile_list: null,
            },
        }
    },
    async created() {
        await this.getSetting()
        await this.getPlans()
    },
    methods: {
        async getSetting() {
            try {
                const result = await settingService.get('plan-list')
                this.item = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        async getPlans() {
            try {
                const result = await planService.getAll()
                this.plans = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        async onSubmit() {
            this.loading = true
            try {
                await settingService.edit('plan-list', this.item)
                this.$dialog.message.success(this.$t('Success'))
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
            this.loading = false
        },
        onChangePlan(variable) {
            if (!this.selected[variable]) {
                return
            }
            this.item[variable].push({
                id: this.selected[variable].id,
                sort: 1,
            })
            this.selected[variable] = null
        },
        onDeletePlan(index, variable) {
            this.item[variable].splice(index, 1)
        },
        planName(planId) {
            const plan = this.plans.find(p => p.id == planId)
            if (!plan)
                return 'Undefined plan'
            return plan.name +
                ` / ${plan.currencyInfo.symbol}${Number(plan.price).toFixed(2)}` + 
                (plan.pricingType.alias == 'recurring' ? ` / ${plan.pricingInterval.name}` : '')
        }
    },
}
</script>